import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import { Config } from './config';

export class SimpleUserInfo {
  public userId: string = '';
  public name: string = '';
  public email: string = '';
  public avatar: string = '';
}

export class FirebaseUtils {
  private static isFirebaseInit: boolean = false;

  public static initFirebase() {
    if (FirebaseUtils.isFirebaseInit) {
      return;
    }
    firebase.initializeApp(Config.firebaseConfig);
    firebase.analytics();
    if (Config.stage === 'dev') {
      firebase.functions().useEmulator('localhost', 5001);
    }
    FirebaseUtils.isFirebaseInit = true;
  }

  public static getFirebaseUserStatus(): Promise<SimpleUserInfo | null> {
    return new Promise<SimpleUserInfo | null>(resolve => {
      try {
        firebase.auth().onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
          if (firebaseUser !== null) {
            const user = new SimpleUserInfo();
            user.userId = firebaseUser.uid;
            user.name = firebaseUser.displayName || '';
            user.email = firebaseUser.email || '';
            user.avatar = firebaseUser.photoURL || '';
            resolve(user);
          } else {
            resolve(null);
          }
        });
      } catch (e) {
        resolve(null);
      }
    });
  }

  public static async getRedirectResult(): Promise<SimpleUserInfo | null> {
    try {
      const result = await firebase.auth().getRedirectResult();
      if (result.user === null) {
        console.log('getRedirectResult null');
        return null;
      }
      const user = new SimpleUserInfo();
      user.userId = result.user.uid;
      user.name = result.user.displayName || '';
      user.email = result.user.email || '';
      user.avatar = result.user.photoURL || '';
      return user;
    } catch (e) {
      console.log('getRedirectResult', e);
      return null;
    }
  }

  public static async logout() {
    await firebase.auth().signOut();
  }
}
