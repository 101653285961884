import { ToastMessage } from '@/models/toastMessage';
import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';

Vue.use(Vuex);

export enum MutationMethod {
  postToast = 'postToast'
}

export enum ActionMethod {
  showToast = 'showToast'
}

export default new Vuex.Store({
  state: {
    showToast: false,
    toast: new ToastMessage()
  },
  mutations: {
    [MutationMethod.postToast]: (state, payload: ToastMessage) => {
      state.toast = payload;
      state.showToast = true;
    }
  },
  actions: {
    [ActionMethod.showToast]: async (context: ActionContext<any, any>, payload: ToastMessage) => {
      context.commit(MutationMethod.postToast, payload);
    }
  },
  modules: {}
});
