import firebase from 'firebase/app';
import 'firebase/auth';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/serviceTasks',
    name: 'ServiceTasks',
    component: () => import(/* webpackChunkName: "serviceTasks" */ '../views/ServiceTasks.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import(/* webpackChunkName: "plan" */ '../views/Plan.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/paymentSuccess',
    name: 'PaymentSuccess',
    component: () => import(/* webpackChunkName: "paymentSuccess" */ '../views/PaymentSuccess.vue'),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    next('/');
  } else {
    next();
  }
});

export default router;
