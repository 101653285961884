








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from '../store';
import { ToastMessage } from '../models/toastMessage';

@Component({})
export default class Toast extends Vue {
  @Action(ActionMethod.showToast)
  showToast!: (payload: ToastMessage) => void;

  public negative() {
    this.$store.state.showToast = false;
    this.$store.state.toast.negative();
  }

  public middle() {
    this.$store.state.showToast = false;
    this.$store.state.toast.middle();
  }

  public positive() {
    this.$store.state.showToast = false;
    this.$store.state.toast.positive();
  }
}
