export class Utils {
  public static isTestEnv(): boolean {
    if (window.location.hostname === 'localhost') {
      return true;
    }
    if (window.location.hostname === 'hosting-logmon.jp.ngrok.io') {
      return true;
    }
    return false;
  }
}
