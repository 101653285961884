


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { FirebaseUtils, SimpleUserInfo } from '../models/firebaseUtils';

@Component({})
export default class AppBar extends Vue {
  public loading: boolean = true;
  public isLogin: boolean = false;
  public user: SimpleUserInfo | null = null;

  public async mounted() {
    this.user = await FirebaseUtils.getFirebaseUserStatus();
    this.loading = false;
  }

  public async logout() {
    await FirebaseUtils.logout();
    location.reload();
  }
}
