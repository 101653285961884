import { ActionMethod } from '@/store';

export class ToastMessage {
  public persistent: boolean = false;
  public title = '';
  public message = '';
  public type: 'error' | 'success' | 'warning' | 'info' = 'error'; // error, success, warning, info

  public wait: Promise<'positive' | 'middle' | 'negative' | 'cancel'>;
  public waitResolve!: (result: 'positive' | 'middle' | 'negative' | 'cancel') => void;

  public positiveBtnTitle: string = '';
  public middleBtnTitle: string = '';
  public negativeBtnTitle: string = '';

  public cancel: () => void = () => {
    if (this.waitResolve !== undefined) {
      this.waitResolve('cancel');
    }
  };

  public positive: () => void = () => {
    if (this.waitResolve !== undefined) {
      this.waitResolve('positive');
    }
  };

  public middle: () => void = () => {
    if (this.waitResolve !== undefined) {
      this.waitResolve('middle');
    }
  };

  public negative: () => void = () => {
    if (this.waitResolve !== undefined) {
      this.waitResolve('negative');
    }
  };

  public constructor() {
    this.wait = new Promise<'positive' | 'middle' | 'negative' | 'cancel'>(resolve => {
      this.waitResolve = resolve as any;
    });
  }

  public setButton(title: string, type: 'positive' | 'middle' | 'negative') {
    if (type === 'positive') {
      this.positiveBtnTitle = title;
    } else if (type === 'middle') {
      this.middleBtnTitle = title;
    } else if (type === 'negative') {
      this.negativeBtnTitle = title;
    }
  }

  public static error(error: Error, ...placeholders: string[]): ToastMessage {
    const t = new ToastMessage();
    t.type = 'error';
    t.title = error.message;
    t.message = error.message;
    for (let i = 0; i < 3; i++) {
      t.message = t.message.replace('{}', placeholders[i] || '');
    }
    return t;
  }

  public static simple(title: string, message: string): ToastMessage {
    const t = new ToastMessage();
    t.type = 'warning';
    t.title = title;
    t.message = message;
    return t;
  }

  public async waitForResult(): Promise<'positive' | 'middle' | 'negative' | 'cancel'> {
    return await this.wait;
  }

  public async show(view: Vue) {
    view.$store.dispatch(ActionMethod.showToast, this);
  }

  public static async httpRequestErrorHandler(
    view: Vue,
    e: Error,
    pUrl = '',
    mURL = '',
    nURL = ''
  ): Promise<'positive' | 'middle' | 'negative' | 'cancel'> {
    const toast = ToastMessage.error(e);
    const positiveURL = pUrl;
    const middleURL = mURL;
    const negativeURL = nURL;
    if (e.message.indexOf('QuotaNotEnougth') !== -1) {
      toast.setButton('Upgrade Plan', 'positive');
      toast.setButton('Okay I Know', 'negative');
    }
    view.$store.dispatch(ActionMethod.showToast, toast);
    const result = await toast.waitForResult();
    if (result === 'positive' && positiveURL !== '') {
      view.$router.push(positiveURL);
    } else if (result === 'middle' && middleURL !== '') {
      view.$router.push(middleURL);
    } else if (result === 'negative' && negativeURL !== '') {
      view.$router.push(negativeURL);
    }
    return result;
  }

  public static async simpleDialog(view: Vue, title: string, message: string) {
    const toast = ToastMessage.simple(title, message);
    view.$store.dispatch(ActionMethod.showToast, toast);
  }
}
