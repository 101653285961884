











































































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  components: {}
})
export default class Home extends Vue {
  public goServiceTask() {
    if (firebase.auth().currentUser === null) {
      alert('請先登入');
      this.$router.push('/login');
    } else {
      this.$router.push('/serviceTasks');
    }
  }
}
