













import { Component, Prop, Vue } from 'vue-property-decorator';
import { FirebaseUtils, SimpleUserInfo } from './models/firebaseUtils';
import AppBar from './components/AppBar.vue';
import Toast from './components/Toast.vue';

@Component({
  components: {
    AppBar,
    Toast
  }
})
export default class App extends Vue {}
