import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { FirebaseUtils } from './models/firebaseUtils';
import { Utils } from './models/utils';

Vue.config.productionTip = false;

FirebaseUtils.initFirebase();

if (Utils.isTestEnv()) {
  if (window.location.hostname === 'hosting-logmon.jp.ngrok.io') {
    firebase.functions().useFunctionsEmulator(window.location.protocol + '//functions-logmon.jp.ngrok.io');
    firebase.firestore().useEmulator('firestore-logmon.jp.ngrok.io', 80);
  } else {
    firebase.functions().useFunctionsEmulator(window.location.protocol + '//' + window.location.hostname + ':' + 5001);
    firebase.firestore().useEmulator('localhost', 8080);
  }
}

firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
});
