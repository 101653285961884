export const Config = {
  stage: 'prod', // prod, dev
  version: 'v1.0',
  devURL: 'http://localhost:8080',
  prodURL: 'https://www.logmon.app',
  firebaseConfig: {
    apiKey: 'AIzaSyDQ_lC4DaCZyt4PIaBbuaiHT9XpdTMQ890',
    authDomain: 'logmon-1450.firebaseapp.com',
    databaseURL: 'https://logmon-1450.firebaseio.com',
    projectId: 'logmon-1450',
    storageBucket: 'logmon-1450.appspot.com',
    messagingSenderId: '533587793044',
    appId: '1:533587793044:web:e5d75745f4a0a44f249081',
    measurementId: 'G-DXKW7TGXEQ'
  }
};
